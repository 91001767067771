import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar'; 
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Testimonials from './components/Testimonials';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Terms from './pages/terms'; 
import PrivacyPolicy from './pages/privacy'; 
import WebsiteDevelopment from './pages/WebsiteDevelopment';
import SEO from './pages/SEO';
import SocialMediaManagement from './pages/SocialMediaManagement';
import GoogleFacebookAds from './pages/GoogleFacebookAds';
import Blog from './pages/Blogs';  
import './App.css';
import ContactUs from './pages/ContactUs';
import Pricing from './pages/Pricing';  
import Technologies from './components/Technologies';
import EcommerceDevelopment from './pages/EcommerceDevelopment';
import AnswerEngineOptimization from './pages/Aeo';
import ServiceCards from './pages/ServiceCards';
import Reviews from './components/Reviews';
import GoogleAdsSection from './components/GoogleAdsSection';
import OurBlog from './components/OurBlog';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navbar is always shown */}
        <Navbar />
        
        {/* Routes */}
        <Routes>
          {/* Home route */}
          <Route
            path="/"
            element={
              <>
                <section id="home">
                  <Home />
                </section>
                <section id="about">
                  <AboutUs />
                </section>
                <section id='reviews'>
                  <Reviews />
                </section>
                <section id="services">
                  <Services />
                </section>
                <section id='ads'>
                  <GoogleAdsSection />
                </section>
                <section id="technologies">
                  <Technologies/>
                </section>
                <section id="portfolio">
                  <Portfolio />
                </section>
                <section id="testimonials">
                  <Testimonials />
                </section>
                <section id='blog'>
                  <OurBlog />
                </section>
                <section id="Faq">
                  <Faq />
                </section>
              </>
            }
          />

          <Route path="/contact-us" element={<ContactUs />} />
          
          {/* Service pages */}
          <Route path="/website-development" element={<WebsiteDevelopment />} />
          <Route path="/search-engine-optimization" element={<SEO />} />
          <Route path="/social-media-management" element={<SocialMediaManagement />} />
          <Route path="/google-facebook-ads" element={<GoogleFacebookAds />} />
          <Route path="/ecommerce-development" element={<EcommerceDevelopment />} />
          <Route path="/answer-engine-optimization" element={<AnswerEngineOptimization />} />
          
          {/* Terms and Privacy */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Blogs */}
          <Route path="/blog" element={<Blog />} /> 
          <Route path="/blog/:slug" element={<Blog />} /> 

          <Route path="/pricing" element={<Pricing />} />

          <Route path="/services" element={<ServiceCards />} />

          {/* Route for invalid paths */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        
        {/* Footer on every page */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
