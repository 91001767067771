import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className="services-container">
      <h2 className="services-title">OUR <span className="highlight">SERVICES</span></h2>
      <div className="services-cards">
        <div className="service-card service-card-orange">
          <img src="/Webdev.webp" alt="Website Development" className="service-icon" />
          <h3 className="service-title">WEBSITE DEVELOPMENT</h3>
          <p className="service-description">
            Saptam Technologies builds custom, responsive, and high-performance websites tailored to your business. Our services include SEO optimization, e-commerce solutions, CMS integration, and speed optimization, ensuring a seamless user experience, enhanced functionality, and scalability for future growth.
          </p>
          <div className="button-container">
            <button
              className="service-button"
              onClick={() => window.location.href = '/website-development'}
            >
              Read More
            </button>
          </div>
        </div>

        <div className="service-card service-card-black">
          <img src="/Ads.webp" alt="Google & Facebook Ads" className="service-icon" />
          <h3 className="service-title">GOOGLE & META ADS</h3>
          <p className="service-description">
            We design and manage targeted Google and Facebook Ads campaigns to increase traffic and conversions. Our team optimizes ad copy, audience targeting, and budget allocation to ensure maximum ROI, delivering cost-effective, high-impact advertising solutions tailored to your business goals.
          </p>
          <div className="button-container">
            <button
              className="service-button"
              onClick={() => window.location.href = '/google-facebook-ads'}
            >
              Read More
            </button>
          </div>
        </div>

        <div className="service-card service-card-orange">
          <img src="/SocialMedia.webp" alt="Social Media Handling" className="service-icon" />
          <h3 className="service-title">SOCIAL MEDIA MANAGEMENT</h3>
          <p className="service-description">
            We manage your social media presence across platforms like Facebook, Instagram, and LinkedIn. Our services include content creation, and strategy development, aimed at increasing brand awareness, fostering customer relationships, and driving conversions through data-driven campaigns.
          </p>
          <div className="button-container">
            <button
              className="service-button"
              onClick={() => window.location.href = '/social-media-management'}
            >
              Read More
            </button>
          </div>
        </div>

        <div className="service-card service-card-black">
          <img src="/Graphicdesigning.webp" alt="Graphic Designing" className="service-icon" />
          <h3 className="service-title">SERACH ENGINE OPTIMIZATION</h3>
          <p className="service-description">
          Our SEO services optimize your website to improve search engine rankings, increase organic traffic, and enhance online visibility. We implement proven strategies, including keyword research, on-page and off-page optimization, and technical SEO, ensuring your brand reaches the right audience and drives sustainable growth.
          </p>
          <div className="button-container">
            <button
              className="service-button"
              onClick={() => window.location.href = '/search-engine-optimization'}
            >
              Read More
            </button>
          </div>
        </div>
      </div>

      {/* Added View More button below the cards */}
      <div className="view-more-container">
        <button
          className="view-more-button"
          onClick={() => window.location.href = '/services'}
        >
          View More
        </button>
      </div>
    </div>
  );
}

export default Services;
