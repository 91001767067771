import React from 'react';
import './ServiceCards.css';

function CustomServiceCards() {
  const services = [
    {
      id: 1,
      icon: '/Webdev.webp',
      title: 'WEBSITE DEVELOPMENT',
      description:
        'Saptam Technologies builds custom, responsive, and high-performance websites tailored to your business. Our services include SEO optimization, e-commerce solutions, CMS integration, and speed optimization, ensuring a seamless user experience, enhanced functionality, and scalability for future growth.',
      link: '/website-development',
      style: 'custom-service-card-orange',
    },
    {
      id: 2,
      icon: '/Ads.webp',
      title: 'GOOGLE & META ADS',
      description:
        'We design and manage targeted Google and Facebook Ads campaigns to increase traffic and conversions. Our team optimizes ad copy, audience targeting, and budget allocation to ensure maximum ROI, delivering cost-effective, high-impact advertising solutions tailored to your business goals.',
      link: '/google-facebook-ads',
      style: 'custom-service-card-black',
    },
    {
      id: 3,
      icon: '/SocialMedia.webp',
      title: 'SOCIAL MEDIA MANAGEMENT',
      description:
        'We manage your social media presence across platforms like Facebook, Instagram, and LinkedIn. Our services include content creation, and strategy development, aimed at increasing brand awareness, fostering customer relationships, and driving conversions through data-driven campaigns.',
      link: '/social-media-management',
      style: 'custom-service-card-orange',
    },
    {
      id: 4,
      icon: '/Graphicdesigning.webp',
      title: 'SERACH ENGINE OPTIMIZATION',
      description:
        "Our SEO services optimize your website to improve search engine rankings, increase organic traffic, and enhance online visibility. We implement proven strategies, including keyword research, on-page and off-page optimization, and technical SEO, ensuring your brand reaches the right audience and drives sustainable growth.",
      link: '/search-engine-optimization',
      style: 'custom-service-card-black',
    },
    {
      id: 5,
      icon: '/ecommerceicon.webp',
      title: 'E-commerce Development',
      description:
        "We specialize in creating powerful and scalable e-commerce platforms tailored to your business needs. Our services include custom online store design, secure payment gateway integration, product management, and seamless checkout experiences, ensuring your customers enjoy a smooth shopping journey. ",
      link: '/ecommerce-development',
      style: 'custom-service-card-black',
    },
    {
      id: 6,
      icon: '/aeo.webp',
      title: 'Answer Engine Optimization',
      description:
        'We specialize in Answer Engine Optimization (AEO), optimizing your content to appear in search engines’ direct answer boxes and other featured snippets. Our strategies focus on improving your visibility in the search results, ensuring your content is structured to provide concise, direct answers that drive traffic and enhance user experience. ',
      link: '/answer-engine-optimization',
      style: 'custom-service-card-orange',
    },    
  ];

  return (
    <div className="custom-services-container">
      <h2 className="custom-services-title">
        OUR <span className="custom-highlight">SERVICES</span>
      </h2>
      <div className="custom-services-grid">
        {services.map((service) => (
          <div
            className={`custom-service-card ${service.style}`}
            key={service.id}
          >
            <img
              src={service.icon}
              alt={service.title}
              className="custom-service-icon"
            />
            <h3 className="custom-service-title">{service.title}</h3>
            <p className="custom-service-description">{service.description}</p>
            <div className="custom-button-container">
              <a href={service.link} className="custom-service-button">
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomServiceCards;
